<template>
  <div class="phoneTable">
    <div class="btnStore">
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content">回收商名称：</span>
        <el-select
          v-model="seachCompanyId"
          filterable
          clearable
          size="small"
          @change="companyChange"
          placeholder="请选择"
          class="el-form-group_isRound"
        >
          <el-option
            v-for="item in companySelectList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="time-select">
        <div
          class="btn"
          :class="dateType == '01' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('01')"
        >
          今日
        </div>
        <div
          class="btn"
          :class="dateType == '03' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('03')"
        >
          本周
        </div>
        <div
          class="btn"
          :class="dateType == '05' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('05')"
        >
          本月
        </div>
      </div>
      <div class="time_allselect">
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          size="small"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
        <span class="add_time" @click="addTimeChoose">{{ addTimeText }}</span>
        <el-date-picker
          style="margin-left: 20px"
          v-model="thanValueTime"
          :picker-options="pickerOptions"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="dateThenTimeChange"
          v-if="addTimeTrue"
        >
        </el-date-picker>
        <span @click="addTimeCancle" v-if="addTimeTrue == true"
          ><img
            src="../../../../../assets/images/home/-s-zy_ico_close.png"
            class="close_img"
        /></span>
      </div>
      <div style="margin-left: 10px">
        <el-button type="primary" :loading="serching" @click="seachStore" size="small" round
          >查询</el-button
        >
      </div>
      <!-- <div style="margin-left: 10px">
        <el-button
          icon="el-icon-download"
          type="success"
          round
          size="small"
          @click="exportExcelStoreFn"
          >导出Excel</el-button
        >
      </div> -->
    </div>
    <el-table
      ref="tableList"
      max-height="500px"
      :data="tableData"
      :default-sort="{ prop: 'dealNum', order: 'descending' }"
      @sort-change="sortTable"
      v-loading="phoneTableLoading"
      border
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="merchantName"
        label="借款商户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="dealNum"
        label="成交订单"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        width="90"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.dealNum.chooseData }}</span>
          <div v-if="row.dealNum.thanUp">
            <div v-if="row.dealNum.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.dealNum.thanData }}%
            </div>
            <div v-else-if="row.dealNum.thanUp == '01'" class="equationGreen">
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.dealNum.thanData }}%
            </div>
            <div v-else-if="row.dealNum.thanUp == '02'" class="equationRed">
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.dealNum.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="dealAmount"
        label="总交易额"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.dealAmount.chooseData }}</span>
          <div v-if="row.dealAmount.thanUp">
            <div v-if="row.dealAmount.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.dealAmount.thanData }}%
            </div>
            <div
              v-else-if="row.dealAmount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.dealAmount.thanData }}%
            </div>
            <div v-else-if="row.dealAmount.thanUp == '02'" class="equationRed">
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.dealAmount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="financeNum"
        label="借款订单"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.financeNum.chooseData }}</span>
          <div v-if="row.financeNum.thanUp">
            <div v-if="row.financeNum.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.financeNum.thanData }}%
            </div>
            <div
              v-else-if="row.financeNum.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.financeNum.thanData }}%
            </div>
            <div v-else-if="row.financeNum.thanUp == '02'" class="equationRed">
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.financeNum.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="financeAmount"
        label="累计借款金额"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.financeAmount.chooseData }}</span>
          <div v-if="row.financeAmount.thanUp">
            <div v-if="row.financeAmount.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.financeAmount.thanData }}%
            </div>
            <div
              v-else-if="row.financeAmount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.financeAmount.thanData }}%
            </div>
            <div
              v-else-if="row.financeAmount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.financeAmount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="financeReturningBalance"
        label="累计还款金额"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.financeReturningBalance.chooseData }}</span>
          <div v-if="row.financeReturningBalance.thanUp">
            <div
              v-if="row.financeReturningBalance.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.financeReturningBalance.thanData }}%
            </div>
            <div
              v-else-if="row.financeReturningBalance.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.financeReturningBalance.thanData }}%
            </div>
            <div
              v-else-if="row.financeReturningBalance.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.financeReturningBalance.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="netServerFeeInto"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        label="服务费净入账"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.netServerFeeInto.chooseData }}</span>
          <div v-if="row.netServerFeeInto.thanUp">
            <div
              v-if="row.netServerFeeInto.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.netServerFeeInto.thanData }}%
            </div>
            <div
              v-else-if="row.netServerFeeInto.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.netServerFeeInto.thanData }}%
            </div>
            <div
              v-else-if="row.netServerFeeInto.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.netServerFeeInto.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="waitFinanceAmount"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        label="当前待还款总额"
        width="130"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.waitFinanceAmount.chooseData }}</span>
          <div v-if="row.waitFinanceAmount.thanUp">
            <div
              v-if="row.waitFinanceAmount.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ row.waitFinanceAmount.thanData }}%
            </div>
            <div
              v-else-if="row.waitFinanceAmount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ row.waitFinanceAmount.thanData }}%
            </div>
            <div
              v-else-if="row.waitFinanceAmount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ row.waitFinanceAmount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="profitRatio"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        label="当前账户余额"
        width="120"
        align="center"
      >
        <!-- <template slot="header">
          <div class="img-el-heard">
            <div>当前账户余额</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="总毛利率=毛利额/回收总额*100%"
                placement="top"
              >
                <img
                  src="../../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template> -->
        <template slot-scope="{ row }">
          <span>{{ row.balance || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="160">
        <template slot-scope="{ row }">
          <span
            style="text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="remarksClick(row, 'see')"
            >门店商户借款分布</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="orderPagina">
      <el-pagination
        float="right"
        background
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="page.total"
        class="el-pagination-c"
        @current-change="handleCurrentChange"
      />
    </div> -->
    <el-dialog
      class="store-dialog"
      title="合作商户借款分布"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      
    >
      <div class="flex flex-jb" style="margin-bottom: 20px">
        <div>
          <span>门店商名称：</span>
          <el-select
            v-model="serchcompanyId"
            filterable
            size="small"
            clearable
            placeholder="请选择或输入商家名称"
          >
            <el-option
              v-for="item in staffList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 10px"
            size="small"
            @click="searchSetting"
          >
            查询
          </el-button>
        </div>
        <div>
          时间：<span v-if="dateType">{{
            dateType == "01"
              ? "今日"
              : dateType == "03"
              ? "本周"
              : dateType == "05"
              ? "本月"
              : ""
          }}</span>
          <span v-else>{{ format(startTime) + "~" + format(endTime) }}</span>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="list"
        :default-sort="{ prop: 'dealNum', order: 'descending' }"
        border
        @sort-change="handleSortChange"
      >
        <el-table-column
          type="index"
          label="排行"
          width="70"
          align="center"
        />
        <el-table-column label="门店商户" prop="companyName" align="center" />
        <el-table-column
          label="成交订单"
          prop="dealNum"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        />
        <el-table-column
          label="总交易额"
          prop="dealAmount"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        />
        <el-table-column
          label="借款订单"
          prop="financeNum"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        />
        <el-table-column
          label="累计借款金额"
          prop="financeAmount"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        >
        </el-table-column>
        <el-table-column
          label="累计还款金额"
          prop="financeReturningBalance"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        >
        </el-table-column>
        <el-table-column
          label="当前待还款总额"
          width="140px"
          prop="waitFinanceAmount"
          align="center"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
        />
      </el-table>
      <Pagination
        :pageNum.sync="remarkDialogPage.pageNum"
        :pageSize.sync="remarkDialogPage.pageSize"
        :total="remarkDialogPage.total"
        @pagination="getData"
      />
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from "moment";
import { brandOrPriceOrderDownTypes, getSortForm } from "../table/define";
import Pagination from "../Pagination.vue";
export default {
  name: "phoneTable",
  components: {
    Pagination,
  },
  props: ["companySelectList"],
  data() {
    return {
      serching:false,
      addTimeText: "+添加对比时间",
      addTimeTrue: false,
      isThan: false,
      isThanTrue: true,
      thanValueTime: [],
      serchcompanyId: "",
      list: [],
      sortForm: {},
      tableData: [],
      staffList: [],
      phoneTableLoading: false,
      commandBtnLoading: false,
      commandShow: false,
      RemarksShow: false,
      remarksVisible: false,
      remarksLoading: false,
      contentRemarks: "",
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      dialogPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      remarkDialogPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "01",
      seachCompanyId: "",
      storeId: "",
      staffName: "",
      companyId: "",
      command: "",
      orderType: "02",
      ackOrDesc: "01", // 降序：01，升序：02
      columnType: "01", // 排序类别【成交量:01、成交总额:02、借款订单:03、累计借款金额:04,累计还款金额：05，服务费净入账：06，当前待还款总额：07，当前账户余额：08】
      dialogVisible: false,
      askVisible: false,
      loading: false,
      storeloading: false,
      companyList: [],
      storeDataList: [],
      DialogDataList: [],
      storeColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "name" }, //回收商家
        { slotName: "quoteCount" },
        { label: "成交量", prop: "deal" },
        { label: "交易额(元)", prop: "dealAmount" },
        { label: "毛利贡献(元)", prop: "grossProfit" },
        { slotName: "dealRate" },
        { label: "补贴金额", prop: "renewSubsidyPriceAll" },
      ],
      DialogTableColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "staffName" },
        { slotName: "storeName" },
        { label: "询价量", prop: "inquiryCount" },
        { label: "成交量", prop: "dealCount" },
        { slotName: "dealRate" },
      ],
      rankingSelectList: [
        {
          value: "02",
          label: "按成交量排行",
        },
        {
          value: "04",
          label: "按交易额排行",
        },
        {
          value: "03",
          label: "按毛利贡献排行",
        },
      ],
      paramsData: {},
      remarksId: "",
      noteUserName: "",
      remarksDialogTable: [
        { label: "备注时间", prop: "createTime" },
        { label: "备注人", prop: "noteUserName" },
        { slotName: "content" },
      ],
      remarksDialogDataList: [],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    addTimeChoose() {
      this.addTimeText = "对比时间";
      this.addTimeTrue = true;
      this.isThan = true;
      this.isThanTrue = true;
    },
    addTimeCancle() {
      this.addTimeText = "+添加对比时间";
      this.addTimeTrue = false;
      this.isThan = false;
      this.isThanTrue = true;
      this.dateTime = [];
      this.thanValueTime = [];
      this.dateOn = "01";
    },
    getAllMiddleAdoptCompany() {
      _api
        .getAllMiddleAdoptCompany({ merchantId: this.remarksId })
        .then((res) => {
          if (res.code === 1) {
            this.staffList = res.data;
          }
        });
    },
    handleSortChange({ order, prop }) {
      this.sortForm = getSortForm({ order, prop }, brandOrPriceOrderDownTypes);
      this.getData();
    },
    getData(e) {
      console.log(e);
      this.loading = true;
      _api
        .findMerchantLoanAmountSpreadDown({
          merchantId: this.remarksId,
          dateType: this.dateType,
          endTime: this.endTime,
          startTime: this.startTime,
          companyId: this.serchcompanyId,
          pageNum: this.remarkDialogPage.pageNum,
          pageSize: this.remarkDialogPage.pageSize,
          ...this.sortForm,
        })
        .then((res) => {
          this.list = res.data.records;
          this.remarkDialogPage.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 列表排序
    sortTable(column) {
      console.log("===", column);
      if (column.prop) {
        // 排序方式
        if (column.order == "ascending") {
          this.ackOrDesc = "02";
        } else if (column.order == "descending") {
          this.ackOrDesc = "01";
        }
        // 排序字段
        switch (column.prop) {
          case "dealNum":
            this.columnType = "01";
            break;
          case "dealAmount":
            this.columnType = "02";
            break;
          case "financeNum":
            this.columnType = "03";
            break;
          case "financeAmount":
            this.columnType = "04";
            break;
          case "netServerFeeInto":
            this.columnType = "05";
            break;
          case "financeReturningBalance":
            this.columnType = "06";
            break;
          case "waitFinanceAmount":
            this.columnType = "07";
            break;
          case "balance":
            this.columnType = "08";
            break;
          default:
            this.columnType = "01";
        }
      }
      this.handleCurrentChange();
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      // if (val) {
      //   this.page.pageNum = val;
      // } else {
      //   this.page.pageNum = 1;
      // }
      const baseRequest = {
        ackOrDesc: this.ackOrDesc,
        orderType: this.columnType,
        dateType: this.dateType,
        endTime: this.endTime,
        thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
        merchantId: this.seachCompanyId,
        // pageNum: this.page.pageNum,
        // pageSize: this.page.pageSize,
        startTime: this.startTime,
      };
      this.phoneTableLoading = true;
      this.paramsData = baseRequest;
      const loading = this.$loading({
        lock: true,
        text: "正在加载数据中，请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      this.serching=true
      _api
        .findMerchantLoanAmountSpreadThan(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            this.serching=false
            this.tableData = res.data;
          }
          loading.close();
          this.phoneTableLoading = false;
        })
        .catch((ree) => {
          this.serching=false
          loading.close();
        });
    },
    //时间选择点击事件
    dataObtain(way) {
      this.dateType = way;
      this.valueTime = "";
      this.startTime = "";
      this.endTime = "";
      this.handleCurrentChange();
    },
    seachStore() {
      if (this.valueTime !== null && this.valueTime) {
        (this.dateType = ""),
          (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        if (!this.dateType) {
          (this.dateType = "01"), (this.startTime = ""), (this.endTime = "");
        }
      }
      this.handleCurrentChange();
    },
    storeDialog(row, type) {
      console.log(row);
      this.companyId = row.id;
      if (type == "01") {
        this.askVisible = true;
        this.getStoreSelectList();
        this.handleCurrentChangeDialog();
      } else {
        this.dialogVisible = true;
        this.companyDealMerchant();
      }
    },
    companyDealMerchant() {
      const baseRequest = {
        orderType: this.orderType,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        companyId: this.companyId,
      };
      this.storeloading = true;
      _api.companyDealMerchant(baseRequest).then((res) => {
        if (res.code === 1) {
          this.storeDataList = res.data;
          this.storeloading = false;
        }
      });
    },
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    onClose() {
      this.askVisible = false;
      this.storeId = "";
      this.staffName = "";
    },
    searchSetting() {
      this.getData();
    },
    handleCurrentChangeDialog(val) {
      console.log(val);
      if (val) {
        this.dialogPage.pageNum = val;
      } else {
        this.dialogPage.pageNum = 1;
      }
      const baseRequest = {
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        companyId: this.companyId,
        storeId: this.storeId,
        staffName: this.staffName,
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
      };
      this.loading = true;
      _api.companyStaffStatistics(baseRequest).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.dialogPage.pageNum = res.data.current;
          this.dialogPage.total = res.data.total;
          this.loading = false;
        }
      });
    },
    format(time) {
      let times = moment(time).format("YYYY-MM-DD");
      return times;
    },
    // 商家查询
    companyChange(e) {
      this.$refs.tableList.clearSort();
      this.ackOrDesc = "01";
      this.seachStore();
    },
    exportExcelStoreFn() {
      this.commandShow = true;
    },
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    commandSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.exportExcelStore();
    },
    exportExcelStore() {
      let data = {
        type: "02",
        command: this.command,
        ...this.paramsData,
      };
      this.commandBtnLoading = true;
      _api.platformOrderRankListExcelMen(data).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false;
            }
          } catch (err) {
            let start = "";
            let end = "";
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD");
              end = moment(Number(data.endTime)).format("YYYY.MM.DD");
            } else if (data.dateType == "01") {
              start = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            } else if (data.dateType == "03") {
              start = moment().weekday(1).format("YYYY.MM.DD");
              end = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            } else if (data.dateType == "05") {
              start = moment().startOf("month").format("YYYY.MM.DD");
              end = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            }
            console.log(moment().startOf("month"));
            const fileName = `【门店商交易排行】${start || ""}${
              end ? "-" + end : ""
            }.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false;
            this.commandShow = false;
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    remarksClick(row, type) {
      this.remarksId = row.merchantId;
      this.serchcompanyId=""
      this.getData();
      this.getAllMiddleAdoptCompany()
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.fc_line {
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
}

.f-line {
  text-decoration: underline;
}
.phoneTable {
  padding: 0 20px 20px;

  .img-el-heard {
    display: flex;
    justify-content: center;

    img {
      margin: 0px 0 -3px 3px;
    }
  }

  .orderPagina {
    padding: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .numBG_1 {
    background-image: url("../../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }

  .numBG_2 {
    background-image: url("../../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }

  .numBG_3 {
    background-image: url("../../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .store-dialog {
    /deep/.el-dialog__body {
      padding: 21px 20px 20px 20px;
    }
  }

  .btnStore {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 22px 0;

    .time-select {
      display: flex;

      .btn {
        box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
        margin: 0px 10px 0px 0;
        text-align: center;
        cursor: pointer;
      }

      .btnType-after2 {
        // width: 33%;
        width: 80px;
        min-width: max-content;
        height: 30px;
        background: #ffffff;
        color: #0981ff;
        line-height: 30px;
        border-radius: 20px;
      }

      .btnType2 {
        width: 80px;
        min-width: max-content;
        height: 30px;
        background: #0981ff;
        color: white;
        line-height: 30px;
        border-radius: 20px;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
}
/deep/.el-table th.el-table__cell > .cell {
  // display: flex;
  // justify-content: center;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.isdeal1 {
  border: 1px solid #f59a23;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 5px;
  color: #f59a23;
  text-align: center;
  margin-left: 5px;
}
.add_time {
  color: #20a0ff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  cursor: pointer;
}
.close_img {
  width: 16px;
  margin-left: 8px;
  margin-top: 2px;
}
.time_allselect{
  /deep/ .el-input__inner {
  width: 250px !important;
}
}
/deep/ .el-range-separator {
  width: 10% !important;
}
.equationBlue {
  color: #20a0ff;
}
.equationGreen {
  color: #49a915;
}
.equationRed {
  color: #ff4949;
}
</style>
