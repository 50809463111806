<template>
  <div class="content_all">
    <div class="orderModel" style="width: 64%">
      <div
        class="box-card"
        style="flex: 1; margin-right: 5px; min-width: 420px"
      >
        <div class="flex-jb flex-ac" style="padding-bottom: 20px">
          <div class="txt-333 font-w card-title" style="font-size: 15px">
            <img src="../image/icon_fzfhtj_.png" alt="" /> 借款金额统计
          </div>
          <div
            class="txt-2 flex"
            style="margin-right: 10px; align-items: center"
          >
            <span style="font-size: 14px">选择时间</span>
            <div style="display: flex">
              <el-button
                class="btn"
                size="small"
                :type="dateType == '01' ? 'primary' : 'default'"
                @click="ClickDateType('01')"
                style="margin-left: 10px"
                >今日</el-button
              >
              <el-button
                class="btn"
                size="small"
                :type="dateType == '03' ? 'primary' : 'default'"
                @click="ClickDateType('03')"
                >本周</el-button
              >
              <el-button
                class="btn"
                size="small"
                :type="dateType == '05' ? 'primary' : 'default'"
                @click="ClickDateType('05')"
              >
                本月
              </el-button>
            </div>
            <el-date-picker
              style="margin-left: 10px"
              v-model="dateTime"
              :picker-options="pickerOptions"
              size="small"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              @change="dateTimeChange"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="data-list">
          <div class="data-list-item">
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: #ffba46">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.lendBalance || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">累计借出</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.returningBalance || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">累计还入</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: #ff687b">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.netIncomeBalance || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">借款净入账</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.severFeeInto || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">服务费入账</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: #ffba46">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.severFeeOut || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">服务费出账</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: #ff687b">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="foodsData.severFeeNetInto || 0"
                    :duration="1500"
                  ></countTo>
                </span>
              </div>
              <div style="display: flex; align-items: center">服务费净入账</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="orderModel" style="width: 35%">
      <div
        class="box-card"
        style="flex: 1; margin-right: 14px; min-width: 420px"
      >
        <div
          class="flex-jb flex-ac"
          style="padding-bottom: 20px; padding-top: 10px"
        >
          <div class="txt-333 font-w card-title" style="font-size: 15px">
            <img src="../image/icon_fzsjtj_.png" alt="" /> 平台额度
          </div>
          <div class="flex-jb flex-ac top_right_text">
            <p style="color: red; margin-right: 20px" @click="changeRecord">
              额度变更记录
            </p>
            <p @click="actionBusiness">
              {{ HomeDataObj.isEnable ? "禁用授信业务" : "启用授信业务" }}
            </p>
          </div>
        </div>
        <div class="data-list">
          <div class="data-list-item">
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: #ffba46">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="HomeDataObj.balanceEmploy || 0"
                    :duration="1500"
                  ></countTo>
                </span>
                <span>元</span>
              </div>
              <div style="display: flex; align-items: center">
                当前已借款
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  content="统计当前有待发货订单的门店数量"
                  placement="top"
                >
                  <img
                    style="width: 13px; height: 13px; margin: 3px 0 0 5px"
                    src="../image/ico_ts_red.png"
                    alt=""
                  />
                </el-tooltip> -->
              </div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="HomeDataObj.balanceLimit || 0"
                    :duration="1500"
                  ></countTo>
                </span>
                <span>元</span>
              </div>
              <div>平台总额度</div>
            </div>
            <div class="center_lin"></div>
            <div class="txt-333 fs-14 magt">
              <div class="color1" style="color: red">
                <span class="fs-30 font-w">
                  <countTo
                    :startVal="0"
                    :endVal="HomeDataObj.balance || 0"
                    :duration="1500"
                  ></countTo>
                </span>
                <span>元</span>
              </div>
              <div>剩余借款额度</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 额度配置记录 -->
    <el-dialog
      title="额度配置记录"
      :visible.sync="managenDlod"
      :close-on-click-modal="false"
      width="1100px"
      @closed="closeManageCredit"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <el-form :inline="true" :model="creditform" class="demo-form-inline">
          <el-form-item label="操作类型">
            <el-select
              size="small"
              v-model="creditform.companyIsEnable"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in onlinePayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="serchcreditSubmit"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <el-button
            @click="entryCredit(1)"
            size="small"
            type="success"
            icon="el-icon-plus"
            >额度上账</el-button
          >
          <el-button
            @click="entryCredit(2)"
            size="small"
            type="danger"
            icon="el-icon-minus"
            >额度下账</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="managementTableColumns"
        :data="managementList"
        :currentPage="managementpage.pageNum"
        :total="managementpage.total"
        @handleCurrentChange="getcademanagentList"
      >
        <el-table-column label="操作类型" slot="contact" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.opt == "00" ? "额度上账" : "额度下账" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="账单金额" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.opt == '00'">+{{ row.amountPrice || "0" }}</span>
            <span v-else style="color: red">-{{ row.amountPrice || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" slot="cancol" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="额度调整原因" slot="isEny" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.cause"
              placement="top"
            >
              <div class="Remarks">
                {{ row.cause || "--" }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="调整后平台总额度" slot="allnum" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.afterAmountLimit || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="调整后平台当前可用额度"
          width="170px"
          slot="action"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.afterAmount || "0" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeManageCredit">取消</el-button>
        <!-- <el-button type="primary" @click.native="codestoreSubmit"
          >确定</el-button
        > -->
      </span>
    </el-dialog>
    <el-dialog
      title="平台总额度调整"
      :visible.sync="RenewalallowShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="resetForm"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <p>当前平台总额度：{{ HomeDataObj.balanceLimit }}元</p>
        <p>当前平台可用额度：{{ HomeDataObj.balance }}元</p>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="操作类型" prop="resource">
          <el-radio-group v-model="ruleForm.resource">
            <el-radio :label="1">额度上账</el-radio>
            <el-radio :label="2">额度下账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="调账金额" prop="price">
          <el-input
            placeholder="请输入调账金额'"
            @input="limitInput($event, 'price')"
            v-model.trim="ruleForm.price"
          ></el-input>
          <span v-if="NumMoney" style="color: #f83232; font-weight: bold">{{
            NumMoney
          }}</span>
        </el-form-item>
        <el-form-item label="调账原因" prop="desc">
          <el-input
            type="textarea"
            placeholder="请输入调账原因，最多50字"
            v-model="ruleForm.desc"
          ></el-input>
        </el-form-item>
        <el-form-item label="动态口令" prop="command">
          <el-input
            v-model="ruleForm.command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="resetForm">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 授信业务 -->
    <el-dialog
      :title="!isEnable ? '启用提示' : '禁用提示'"
      :visible.sync="extensionShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="extensionClosed"
    >
      <div class="enable-tip">
        <div>
          {{
            !isEnable
              ? "说明：启用后，当前所有已启用授信商户成交后，平台按照授信比例进行垫资，请提前核实好授信商户。"
              : "说明：禁用后所有已启用授信商户成交后，仍由商户支付全款，平台不再付款。"
          }}
        </div>
        <div class="tip-sure">
          是否确认{{ !isEnable ? "启用" : "禁用" }}授信业务？
        </div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="dynamicCode"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer footer_acc" >
       <div style="text-align: left;color: #333333;font-size: 15px;">
          <p>最近{{ isEnable ? "启用" : "禁用" }}人：{{HomeDataObj.enableAdminName}}-{{HomeDataObj.enableAdminAcc}}</p>
          <p>{{ isEnable ? "启用" : "禁用" }}时间：{{HomeDataObj.enableTime}}</p>
       </div>
       <div>
        <el-button @click.native="extensionClosed">取消</el-button>
        <el-button type="primary" @click.native="extensionSubmit">确定</el-button>
       </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import _api from "@/utils/request";
export default {
  name: "orderModel",
  components: { countTo },
  data() {
    return {
      managementList: [],
      loading: false,
      isEnable: false,
      extensionShow: false,
      dynamicCode: "",
      NumMoney: "",
      RenewalallowShow: false,
      rules: {
        price: [{ required: true, message: "请输入调账金额", trigger: "blur" }],
        command: [
          { required: true, message: "请输入动态口令", trigger: "blur" },
        ],
        resource: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        desc: [{ required: true, message: "请输入调账原因", trigger: "blur" }],
      },
      ruleForm: {
        price: "",
        command: "",
        resource: "",
        desc: "",
      },
      managementpage: {
        pageNum: 1,
        pageSize: 10,
      },
      managementTableColumns: [
        { slotName: "contact" }, //
        { slotName: "copyType" }, //
        { slotName: "cancol" }, //
        { label: "操作时间", prop: "createTime" },
        { slotName: "isEny" }, //
        { slotName: "allnum" }, //
        { slotName: "action" }, //
      ],
      onlinePayList: [
        {
          label: "上账",
          value: "00",
        },
        {
          label: "下账",
          value: "01",
        },
      ],
      creditform: {
        companyIsEnable: "",
      },
      managenDlod: false,
      dateType: "",
      HomeDataObj: {},
      foodsData: {},
      succeData: {},
      //快递下单统计
      fdParams: {
        endTime: "",
        startTime: "",
      },
      //发货成功快递统计
      sucsseParams: {
        endTime: "",
        startTime: "",
      },
      dateTime: [],
      succssedateTime: [],
      day60: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
    };
  },
  created() {
    this.getwaitSendStoreStatistics();
    this.getorderSendStatistics(2);
    this.getsendSuccessStatistics(2);
  },
  methods: {
    actionBusiness() {
      this.isEnable = this.HomeDataObj.isEnable;
      this.extensionShow = true;
    },
    extensionSubmit() {
      if (!this.dynamicCode) {
        return this.$message.error("请输入谷歌动态验证码");
      }
      _api
        .enableFinanceAccount({
          command: this.dynamicCode,
          isEnable: !this.isEnable,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.extensionShow = false;
            this.getwaitSendStoreStatistics();
          } else {
            this.$message.error(res.msg || "系统错误");
          }
        })
        .catch((err) => {});
    },
    extensionClosed() {
      this.extensionShow = false;
      this.dynamicCode = "";
    },
    entryCredit(type) {
      this.ruleForm.resource = type;
      this.RenewalallowShow = true;
    },
    changeRecord() {
      this.getcademanagentList();
      this.managenDlod = true;
    },
    limitInput(value, name) {
      console.log(value);
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.ruleForm[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.ruleForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.NumMoney = valJoin.match(/^\d*(\.?\d{0,2})/g)[0]
        ? this.changeNumMoneyToChinese(valJoin.match(/^\d*(\.?\d{0,2})/g)[0])
        : "";
    },
    resetForm() {
      this.NumMoney=""
      this.RenewalallowShow = false;
      this.$refs["ruleForm"].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api
            .updateFinanceUpDown({
              amountPrice: this.ruleForm.price,
              cause: this.ruleForm.desc,
              command: this.ruleForm.command,
              opt: this.ruleForm.resource == 1 ? "00" : "01",
            })
            .then((res) => {
              this.getcademanagentList();
              this.getwaitSendStoreStatistics();
              this.RenewalallowShow = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 授信商家管理
    getcademanagentList(val) {
      console.log(val);
      if (val) {
        this.managementpage.pageNum = val;
      } else {
        this.managementpage.pageNum = 1;
      }
      _api
        .findFinanceUpDownLogs({
          pageNum: this.managementpage.pageNum,
          pageSize: this.managementpage.pageSize,
          opt: this.creditform.companyIsEnable,
        })
        .then((res) => {
          if (res.code == 1) {
            this.managementList = res.data.records;
            this.managementpage.total = res.data.total;
            this.managementpage.pageNum = res.data.current;
          }
        });
    },
    //授信商家查询
    serchcreditSubmit() {
      this.getcademanagentList();
    },
    closeManageCredit() {
      this.creditform = {
        companyIsEnable: "",
      };
      this.managenIdsList = [];
      this.managenDlod = false;
    },
    ClickDateType(val) {
      this.dateTime = "";
      this.dateType = val;
      _api.findLoanAmountStatistics({ dateType: val }).then((res) => {
        this.foodsData = res.data;
      });
    },
    getwaitSendStoreStatistics() {
      _api.findPlatformLimit().then((res) => {
        this.HomeDataObj = res.data;
      });
    },
    //快递下单统计
    getorderSendStatistics(num) {
      this.dateType = "";
      const endtime = new Date().getTime();
      const startDate = this.getDay(-30);
      if (num === 1 && this.dateTime) {
        this.fdParams.startTime = this.dateTime[0];
        this.fdParams.endTime = this.dateTime[1];
      } else {
        console.log(endtime, startDate, new Date(startDate).getTime());
        this.fdParams.startTime = new Date(startDate).getTime();
        this.fdParams.endTime = endtime;
        this.dateTime = [new Date(startDate), new Date()];
      }
      _api.findLoanAmountStatistics(this.fdParams).then((res) => {
        this.foodsData = res.data;
      });
    },
    //发货成功快递统计
    getsendSuccessStatistics(num) {
      const endtime = new Date().getTime();
      const startDate = this.getDay(-30);
      if (num === 1 && this.succssedateTime) {
        this.sucsseParams.startTime = this.succssedateTime[0];
        this.sucsseParams.endTime = this.succssedateTime[1];
      } else {
        console.log(endtime, startDate, new Date(startDate).getTime());
        this.sucsseParams.startTime = new Date(startDate).getTime();
        this.sucsseParams.endTime = endtime;
        this.succssedateTime = [new Date(startDate), new Date()];
      }
      _api.getsendSuccessStatistics(this.sucsseParams).then((res) => {
        this.succeData = res.data;
      });
    },
    //获取当前日期
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "/" + tMonth + "/" + tDate;
    },
    //获取当前月份
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    //快递下单统计时间区间查询
    dateTimeChange(e) {
      this.getorderSendStatistics(1);
    },
    //发货成功快递统计时间区间查询
    SuccdateTime(e) {
      this.getsendSuccessStatistics(1);
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义
      var Symbol = ""; //正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert("超出最大处理数字");
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = "";
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) {
        //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != "") {
        //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == "") {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == "") {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.footer_acc{
  display: flex;
  justify-content: space-between;
}
.content_all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}
.magt {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //  width: 300px;
}

.center_lin {
  width: 1px;
  height: 68px;
  background: #e0e0e0;
}

.orderModel {
  width: 40%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 20px;
  background-color: #fff;

  .modelbg {
    position: relative;
    width: 420px;
    height: 160px;
    background-size: 100% 100%;
    padding-top: 5px;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .center {
      // position: absolute;
      // top: 10px;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // width: 100%;
      margin-left: 180px;

      .today {
        display: flex;
        align-items: center;

        // padding: 10px 0 0 0;
        div:first-child {
          // width: 22%;
          // height: 21px;
          font-size: 16px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          font-weight: bold;
          color: #333333;
          // line-height: 41px;
        }

        div:last-child {
          // width: 50%;
          // height: 35px;
          font-size: 46px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          // line-height: 55px;
        }
      }
    }
  }

  .NumberColor_First {
    color: #0981ff;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Sec {
    color: #ffaf29;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Third {
    color: #ff687b;
    font-size: 26px !important;
    display: flex;
  }

  .NumberColor_Four {
    color: #bb8eef;
    font-size: 26px !important;
    display: flex;
  }

  // .firstBG{
  //     background-image: url('../../../../assets/images/home/bg_img_001.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .SecBG{
  //     background-image: url('../../../../assets/images/home/bg_img_002.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .ThirdBG{
  //     background-image: url('../../../../assets/images/home/bg_img_003.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  // .FourBG{
  //     background-image: url('../../../../assets/images/home/bg_img_004.png');
  //     // background-size:cover;
  //     .title{
  //         position: absolute;
  //         right: 2%;
  //         top: 7%;
  //     }
  // }
  .ml-10 {
    margin-left: 10px;
  }

  .box-card {
    background: #ffffff;
    border-radius: 4px;
    border-radius: 20px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    /deep/.el-input__inner {
      width: 244px !important;
    }

    /deep/.el-range-separator {
      width: max-content !important;
    }
  }

  .card-title {
    padding-left: 16px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }

  .date-click-item {
    margin-left: 30px;
    cursor: pointer;
  }

  .date-click-item:hover {
    text-decoration: underline;
    color: #20a0ff;
  }

  .date-click-item.active {
    color: #20a0ff;
    font-weight: 600;
  }

  .data-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 150px;
    padding: 20px 20px;
    border-top: 1px solid #edeffd;
    // flex: 1;
    flex-wrap: wrap;
  }

  .data-list-item {
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
  }

  .store-num {
    padding: 20px;
    min-height: 150px;
  }

  .store-num-item {
    height: 90px;
    width: 170px;
    background: #eff2fa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .color1 {
    color: #20a0ff;
  }

  .color2 {
    color: #ff4949;
  }

  .color3 {
    color: #13ce66;
  }

  .color4 {
    color: #f7ba2a;
  }

  .font-w {
    font-weight: bold;
  }

  .txt-2 {
    color: #8492a6;
  }

  .txt-333 {
    color: #333333;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-30 {
    font-size: 30px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .flex {
    display: flex;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-ac {
    display: flex;
    align-items: center;
  }
}
.top_right_text {
  font-size: 14px;
  color: #0981ff;
  cursor: pointer;
  > p {
    text-decoration: underline;
  }
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
// 备注说明
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
}
</style>
