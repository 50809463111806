<template>
  <div class="echartsOrder">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_ddtj_.png" alt />
        </div>
        <div class="case-title_max">借贷统计折线图</div>
      </div>
    </div>
    <div class="echarts">
      <div class="echarts_right">
        <div class="btnEcharts">
          <!-- <div>
            <el-select v-model="companyId" clearable placeholder="请选择门店商家" filterable :disabled="disabled == '01'"
              style="margin:0" @change="handleChangeInquiry">
              <el-option v-for="item in companySelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div> -->
          <div>
            <span>回收商名称：</span>
            <el-select v-model="merchantId" clearable placeholder="请选择回收商家" filterable style="margin:0"
              :disabled="disabled == '02'" @change="handleChange">
              <el-option v-for="item in merchantSelectList" :key="item.merchantId" :label="item.merchantName"
                :value="item.merchantId"></el-option>
            </el-select>
          </div>
          <div>
            <el-button @click="dataObtain('week')">过去七天</el-button>
          </div>
          <div>
            <el-button @click="dataObtain('month')">过去三十天</el-button>
          </div>
          <div>
            <el-date-picker v-model="valueTime" type="daterange" range-separator="至" value-format="timestamp"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
          </div>
          <div>
            <el-button type="primary" :loading="serching"  @click="seachEcharts">查询</el-button>
          </div>
        </div>
        <div class="Polyline" id="chartLineBoxDeal"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
const echarts = require("echarts");
export default {
  name: "echartsOrder",
  props: ['type', 'orderTotal', 'orderMonth', 'orderWeek', 'companySelectList', "merchantSelectList"],
  data() {
    return {
      serching:false,
      monthData:{},
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "",
      dataValueX: [],
      dataValueY: [],
      dataValueY2: [],
      dataValueY3: [],
      companyId: '',
      merchantId: '',
      shanpsSelectList: [],
      disabled: '00'
    };
  },
  created() {
  },
  mounted() {
    this.echartsData()
  },
  computed: {
  },
  methods: {
    echartsData() {
      this.dataValueY = []
      this.dataValueX = []
      this.dataValueY2 = []
      if (this.startTime) {
        console.log(this.startTime)
      } else {
        this.endTime = Date.parse(new Date())
        this.startTime = this.endTime - 60000 * 60 * 24 * 7
      }
      const vo = {
        type: "01",
        startTime: this.startTime,
        endTime: this.endTime,
        merchantId: this.merchantId,
      };
      this.serching=true
      _api.findLoanAmountStatisticsLine(vo).then(res => {
        if (res.code === 1) {
          this.serching=false
          for (let i = 0; i < res.data.length; i++) {
            this.dataValueY.push(res.data[i].lendBalance);
            this.dataValueY2.push(res.data[i].returningBalance);
            this.dataValueX.push(res.data[i].createTime);
          }
          this.$nextTick(() => {
            myEcharts.setOption(option, true)
          })
        }
      }).catch(() => {
        this.serching=false
      });
      var myEcharts = echarts.init(document.getElementById("chartLineBoxDeal"));
      let seriesArr = [
          {
            name: "累计借款",
            type: "line",
            color: '#5470c6',
            smooth: true,
            data: this.dataValueY
          },
          {
            name: "累计还款",
            type: "line",
            color: '#91cc75',
            smooth: true,
            data: this.dataValueY2
          },
        ]
      const option = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ['累计借款', '累计还款'],
          right: '135',
          top: '20'
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dataValueX
        },
        yAxis: {
          name: "",
          type: "value"
        },
        series: seriesArr
      }
      console.log(option);
      myEcharts.setOption(option, true);
    },
    seachEcharts() {
      if (this.valueTime !== null && this.valueTime) {
        (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        (this.startTime = ""), (this.endTime = "");
      }
      console.log(this.startTime)
      console.log(this.endTime)
      this.echartsData();
    },
    //点击事件
    dataObtain(way) {
      this.endTime = Date.parse(new Date())
      if (this.dateType == way) {
        return
      }
      this.dateType = way
      if (way === 'week') {
        this.startTime = this.endTime - 60000 * 60 * 24 * 7
        this.echartsData()
      } else {
        this.startTime = this.endTime - 60000 * 60 * 24 * 30
        this.echartsData()
      }
    },
    handleChange(val) {
      this.merchantId = val
      this.companyId = ""
      this.disabled = '01'
      if (!val) {
        this.disabled = '00'
      }
      this.echartsData()
      this.$forceUpdate()
      console.log(this.merchantId)
    },
    handleChangeInquiry(val) {
      console.log(val)
      this.companyId = val
      this.disabled = '02'
      this.merchantId = ""
      if (!val) {
        this.disabled = '00'
      }
      this.echartsData()
      this.$forceUpdate()
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.echartsOrder {
  width: 100%;
  height: 660px;
  background-color: white;

  .el-button.is-round {
    padding: 10px 33px !important;
  }

  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f6f6f6;

    .icon_title {
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .case-title_max {
        display: inline-block;
        height: 17px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .echarts {
    width: 100%;
    height: 560px;
    display: flex;

    &_left {
      width: 15%;

      .order {
        margin-top: 50px;
        width: 250px;
        height: 100px;
        text-align: left;

        .title {
          margin: 0 auto;
          width: 150px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }

        .number {
          margin: 0 auto;
          width: 150px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #333333;
          line-height: 30px;

          .number-color1 {
            color: #5470c6;
            font-weight: bold;
          }

          .number-color2 {
            color: #91cc75;
            font-weight: bold;
          }

          .number-color3 {
            color: #fac858;
            font-weight: bold;
          }
        }
      }
    }

    &_right {
      width: 85%;

      .btnEcharts {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          margin: 20px;
        }
      }

      .Polyline {
        width: 100%;
        height: 560px;
      }
    }
  }
}
</style>
